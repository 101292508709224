.page {
    margin: 20px;
}

.p,.body{
    padding-left: 40px;
    padding-right: 40px;
}

.Link button {
    margin-left: 10px;
}
