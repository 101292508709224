.header {
    font-size: 36px;
    font-weight: bold;
    text-align: left;
    color: #333;
    margin-bottom: 25px;
    margin-top: 25px;
}

body {
    margin-left:0px;
    margin-right:0px;
}

.page {
    margin: 20px;
}