
#displayfields {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 200px;
}

.calculatorApp {
  border: 1px solid black;
  padding: 10px;
  margin: 10px;
  width: 250px;
}
#mycalc {
  display: flex;
  justify-content: center;
}

.keypadbutton {
  width: 30px;
  margin-right: 5px;
}

#keypad {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}